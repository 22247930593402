.loading-wrapper {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.5);
  z-index: 1000;
}

.loader {
  position: absolute;
  top: 50%;
  left: 48%;
}

.line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 6px;
  width: 4px;
  height: 35px;
}

.line:nth-child(1) {
  background: white;
}

.line:nth-child(2) {
  animation-delay: 180ms;
  background: white;
}

.line:nth-child(3) {
  animation-delay: 360ms;
  background: white;
}

.line:nth-child(4) {
  animation-delay: 540ms;
  background: white;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}
